<template>
  <div class="text-sm w-9/12 sm:w-auto sm:px-0">
    <div class="pt-6">
      <div class="relative tip-container" ref="tooltipContainer">
        <button
          @click="toggleTooltip"
          class="
            focus:outline-none
            text-white
            p-2
            w-full
            h-minput
            bg-kbutton-1
            hover:bg-kbutton-2
            active:border active:border-kbutton-3
            rounded
            flex
            justify-between
            items-center
          "
        >
          <div class="flex">
            <span class="text-sm font-semibold">{{ $t("menus.btn") }}</span>
          </div>
          <div>
            <Down />
          </div>
        </button>
        <div class="absolute tip" :class="visible ? 'showTooltip' : 'tooltip'">
          <div class="bg-white p-4 space-y-8 rounded">
            <div
              @click="goTo('/dashboard/pay')"
              class="flex items-center space-x-2 cursor-pointer"
            >
              <GrayInvoice />
              <span> {{ $t("counterRecharge.process1") }} </span>
            </div>
            <div
              @click="goTo('/dashboard/recharge')"
              class="flex items-center space-x-2 cursor-pointer"
            >
              <GrayCompt />
              <span>
                {{ $t("counterRecharge.process2") }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <ul>
          <router-link
            tag="li"
            to="/dashboard"
            active-class="text-tex-2 font-bold bg-tex-10"
            exact
            ><a
              @click="isOpenActive"
              class="
                pl-3
                focus:outline-none
                w-full
                py-4
                flex
                justify-start
                items-center
              "
            >
              <Iconhome
                :class="
                  subIsActive('/dashboard')
                    ? 'svg-fill-active svg-fill'
                    : 'svg-fill-current'
                "
              />
              <div class="ml-2 text-sm">{{ $t("menus.home") }}</div></a
            >
          </router-link>

          <router-link
            tag="li"
            to="/dashboard/counters"
            active-class="text-tex-2 font-bold bg-tex-10"
            exact
            ><a
              @click="isOpenActive"
              class="
                pl-3
                focus:outline-none
                w-full
                py-4
                flex
                justify-start
                items-center
              "
            >
              <Iconcompteurs
                :class="
                  subIsActive('/dashboard/counters')
                    ? 'svg-fill-active'
                    : 'svg-fill-current'
                "
              /><span class="ml-2 text-sm">{{ $t("menus.meters") }}</span></a
            >
          </router-link>

          <!-- <router-link tag="li" to="/dashboard/paymentmethods" active-class="text-tex-2 font-bold bg-tex-10" exact><a
                            @click="isOpenActive"
                            class="pl-3 focus:outline-none  w-full py-4 flex justify-start items-center">
                            <Iconpaymethod :class="subIsActive('/dashboard/paymentmethods') ? 'svg-fill-active': 'svg-fill-current'"/><span
                                class="ml-2 text-sm ">{{ $t("menus.paymentMethods") }}</span></a>
                    </router-link> !-->

          <router-link
            tag="li"
            to="/dashboard/bills"
            active-class="text-tex-2 font-bold bg-tex-10"
            exact
            ><a
              @click="isOpenActive"
              class="
                pl-3
                focus:outline-none
                w-full
                py-4
                flex
                justify-start
                items-center
              "
            >
              <Iconfactures
                :class="
                  subIsActive('/dashboard/bills')
                    ? 'svg-fill-active'
                    : 'svg-fill-current'
                "
              /><span class="ml-2 text-sm">{{ $t("menus.bills") }}</span></a
            >
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Iconhome from "../../assets/svg/dashbIcons/Accueil.svg?inline";
import Iconcompteurs from "../../assets/svg/dashbIcons/mescompteur.svg?inline";
import Iconfactures from "../../assets/svg/dashbIcons/mesfactur.svg?inline";
//import Iconpaymethod from '../../assets/svg/dashbIcons/moyenspaiment.svg?inline'
import Down from "../../assets/svg/dashbIcons/down.svg?inline";
import GrayInvoice from "../../assets/svg/dashbIcons/grayInvoice.svg?inline";
import GrayCompt from "../../assets/svg/dashbIcons/grayCompt.svg?inline";
export default {
  components: {
    GrayCompt,
    GrayInvoice,
    Iconhome,
    Iconcompteurs,
    Iconfactures,
    //Iconpaymethod,
    Down,
  },
  data() {
    return {
      visible: false,
    };
  },

  methods: {
    toggleTooltip() {
      this.visible = !this.visible;
    },

    goTo(route) {
      this.$router.push(route);
      this.visible = false;
    },

    isOpenActive() {
      return this.$store.commit("mutIsOpen", false);
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        if (this.$route.path === path) return true;
        else {
          return false;
        } //class="svg-fill-current" :class="{'svg-fill-active': subIsActive('/dashboard/counter')}" :class="subIsActive('/dashboard/counter') ? 'svg-fill-active': 'svg-fill-current'"
      });
    },
  },

  mounted() {
    const { tooltipContainer } = this.$refs;
    document.addEventListener("click", (event) => {
      if (tooltipContainer && !tooltipContainer.contains(event.target)) {
        this.visible = false;
      }
    });
  },
};
</script>

<style scoped>
.showTooltip {
  max-height: 1000px;
  opacity: 1;
}

.tip-container {
  width: 90%;
  margin: auto;
}

.tip {
  box-shadow: 0px 2px 8px 0px #00000040;
  box-sizing: border-box;
  left: 0;
  top: 100%;
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  border-radius: 4px;
}

.svg-fill-active {
  color: #f11c33;
}
.tooltip {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}
.svg-fill-current {
  color: #808080;
}
.svg-fill {
  fill: #f11c33;
}
</style>