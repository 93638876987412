<template>
    <div class="bg-white hidden sm:block hcustom">
        <KdMenu/>
    </div>
</template>

<script>
import KdMenu from './KdMenu'
export default {
    components: {
        KdMenu
    }
}
</script>

<style scoped>
.hcustom{
    height: 100vh;
}
</style>