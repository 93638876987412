<template>
    <div class="grid sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 ">
      <kdHeader class="lg:col-span-5 sm:col-span-3 xl:col-span-6"></kdHeader>
      <kdLeftbar class="lg:col-span-1 sm:col-span-1 xl:col-span-1"></kdLeftbar>
      <routerView class="lg:col-span-4 sm:col-span-2 xl:col-span-5 hcustom"></routerView>
    </div>
</template>

<script>
import KdHeader from '../dashboardPartials/KdHeader';
import KdLeftbar from '../dashboardPartials/KdLeftbar';
//import axios from 'axios'
import { socket } from "@/io";
export default {
  components: {
    KdHeader,
    KdLeftbar,
  },
  data(){
    return{
      
    }
  },
  

  async mounted() {
      await this.$store.commit('mutGenerateCounters'); 
      //console.log(this.$store.getters.getCounter)
      if(this.$store.getters.getCounterHasLength) {
        this.$store.commit('mutdashboardPaymentStep', true)
        //console.log(this.$store.getters.getdashboardPaymentStep)
      }
      
      socket.on(`user_account_state_change${this.$store.getters.getAuthUser.id}`, (data) => {
        console.log('disconnect by socket: ', data);
        this.$router.push('signsuccess')
        this.$store.dispatch('logout')
      })
  },
  
}
</script>

<style scoped>
.hcustom{
    height: 100vh - 4rem;
}
</style>