<template>
  <div>
    <div class="bg-white py-2 sm:py-0 h-16 shadow flex flex-row items-center justify-between">
      <button class="sm:hidden focus:outline-none px-2 pl-3" @click="isOpenActive(true)">
        <Iconmbmenu />
      </button>
     <span><Iconkkiapay class="mr-2 sm:mr-0 sm:ml-10" /></span>
      <div class="relative">
        <div @click="profilActive = !profilActive" class="flex flex-row items-center mr-10 sm:mr-16">
          <div class="flex items-center mr-10 sm:mr-12">
            <div class="-mr-1 rounded-full h-11 w-11 flex items-center justify-center">
              <img src="../../assets/svg/avatar.svg" alt="" />
            </div>
            <span class="-ml-12  text-center text-white text-xs rounded-full h-5 w-5 select-none "></span>
            <!-- <span class="-ml-12 bg-red-600 text-center text-white text-xs rounded-full h-5 w-5 select-none ">3</span> -->
          </div>
          <p class="sm:mr-8 select-none" style="text-transform: capitalize;">{{ firstName }}</p>
        </div>
        <div v-if="profilActive" id="amburger" class="bg-white w-full py-2 px-4 absolute right-0 text-sm flex flex-col">
          <router-link to="/dashboard/account">
            <div @click="profilActive = !profilActive" class="flex items-center sm:pr-16">
              <IconUser /><span class="ml-2">{{ $t("profil.profil") }}</span>
            </div>
          </router-link>
          <button @click="logout" class="flex items-center mt-2 focus:outline-none">
            <IconLogout /><span class="ml-2">{{ $t("profil.deconnect") }}</span>
          </button>
        </div>
      </div>
    </div>

    <div  class="sm:hidden transform top-0 w-8/12 left-0 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30"
      :class="isOpen ? 'translate-x-0' : '-translate-x-full'">

        <KdMenu class="bg-white hcustom w-full" />
      
    </div>
    <transition 
      enter-class="opacity-0" 
      enter-active-class="ease-out transition-medium" 
      enter-to-class="opacity-100"
      leave-class="opacity-100" 
      leave-active-class="ease-out transition-medium" 
      leave-to-class="opacity-0">
      <div @keydown.esc="isOpenActive(false)" v-show="isOpen" class="sm:hidden z-10 fixed inset-0 transition-opacity">
        <div @click="isOpenActive(false)" class="sm:hidden absolute inset-0 bg-black opacity-75" tabindex="0"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import Iconkkiapay from "../../assets/svg/dashbIcons/kkiapay2.svg?inline";
//import Iconavatar from '../../assets/svg/avatar.svg?inline';
import KdMenu from "./KdMenu";
import Iconmbmenu from "../../assets/svg/dashbIcons/menumobil.svg?inline";
//import Iconclmenu from "../../assets/svg/dashbIcons/close.svg?inline";
import IconUser from "../../assets/svg/dashbIcons/profiluser.svg?inline";
import IconLogout from "../../assets/svg/dashbIcons/logout.svg?inline";
export default {
  components: {
    Iconkkiapay,
    //Iconavatar,
    Iconmbmenu,
    //Iconclmenu,
    IconUser,
    IconLogout,
    KdMenu,
  },
  data() {
    return {
      profilActive: false,
    };
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (process.client) {
          if (isOpen) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      },
    },
  },
  computed: {
    isOpen() {
      return this.$store.getters.getIsOpen;
    },
    firstName(){
      return this.$store.getters.getAuthUser.firstName
    }
  },
  methods: {
    isOpenActive(bool) {
      return this.$store.commit("mutIsOpen", bool);
    },
    active(){
      this.profilActive=!this.profilActive
    },
    logout(){
      this.$router.push('/signsuccess')
      this.profilActive = !this.profilActive
      this.$store.dispatch('logout')
    }
  },
  mounted() {
   
   /*  document.addEventListener("click", (evt) => {
    const flyoutElement = document.getElementById("profilin");
    let targetElement = evt.target; // clicked element

    do {
        if (targetElement == flyoutElement) {
            // This is a click inside. Do nothing, just return.
            console.log('insiiide')
        }
        // Go up the DOM
        targetElement = targetElement.parentNode;
    } while (targetElement);

    // This is a click outside.
    console.log('outsiiide')
});
      document.addEventListener("keydown", e => {
      if (e.keyCode == 27 && this.isOpen) this.isOpenActive(false);
    }); */
  },
};
</script>

<style scoped>
.hcustom {
  height: 100vh;
}
.imgResponsive {
    width: 100%;
    height: auto;
}
</style>
